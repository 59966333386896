<template>
  <div>
    <div class="list">
      <div class="item">
        <span style="margin-right:20px">商品类目</span>
        <el-select v-model="value" placeholder="请选择商品类目">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span style="margin-right:20px">商品名称</span>
        <el-select v-model="value" placeholder="请选择商品名称">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button class="btn">查找</el-button>
      <el-button class="btn" type="primary" @click="list = true"
        >添 加</el-button
      >
    </div>
    <el-table
      :data="tableData.data"
      border
      :close="close"
      style="width: 100%;margin-top:20px"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="id" label="商品id"> </el-table-column>
      <el-table-column label="商品图片">
        <template slot-scope="scope">
          <img
            style="width:100px;height:50px"
            :src="scope.row.veg_logo"
            alt=""
            @click="$refs.img.previewImage(scope.row.veg_logo)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="veg_name" label="商品名称"> </el-table-column>
      <el-table-column prop="veg_alias_name" label="商品别名">
      </el-table-column>
      <el-table-column prop="veg_code" label="商品编码"> </el-table-column>
      <el-table-column prop="category_pname" label="所属类目">
      </el-table-column>
      <el-table-column prop="full_address" label="商品产地"> </el-table-column>
      <el-table-column prop="address" label="溯源信息"> </el-table-column>
      <el-table-column prop="address" label="添加来源"> </el-table-column>
      <el-table-column prop="address" label="销量"> </el-table-column>
      <el-table-column prop="address" label="商户数量"> </el-table-column>
      <el-table-column prop="created_at" width="160px" label="添加时间">
      </el-table-column>
      <el-table-column label="商品状态">
        <template slot-scope="scope">
          <el-link v-if="scope.row.status * 1 === 0" type="danger"
            >待审核</el-link
          >
          <el-link v-else-if="scope.row.status * 1 === 1" type="success"
            >审核通过</el-link
          >
          <el-link v-else type="danger">审核不通过</el-link>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title="新增商品审核"
      :close-on-click-modal="false"
      :visible.sync="add"
      width="30%"
      @close="close"
      center
    >
      <div class="amend">
        <div>
          <b>商品名称：</b>
          <b>{{ ruleVlue.veg_name }}</b>
        </div>
        <div>
          <b>商品别名：</b>
          <b>{{ ruleVlue.veg_alias_name }}</b>
        </div>
      </div>
      <div class="amend">
        <b>所属类目</b>
        <el-cascader
          :options="ruleForm.CategoryList"
          :props="{ value: 'id', label: 'name', checkStrictly: true }"
          clearable
          v-model="categoryid"
          style="width:80%"
        ></el-cascader>
      </div>
      <div><b>商品产地：广东深圳</b></div>
      <div class="margin20">
        <b>商品图片：</b>
        <img
          style="width:100px;height:100px; vertical-align: top;"
          :src="ruleVlue.veg_logo"
          alt=""
        />
      </div>
      <div>
        <el-radio v-model="ruleVlue.status" label="1">通过</el-radio>
        <el-radio v-model="ruleVlue.status" label="2">不通过</el-radio>
      </div>
      <div class="margin20">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入内容200字以内哦！"
          v-model="ruleVlue.refuse_reson"
          maxlength="200"
          show-word-limit
          resize="none"
        >
        </el-input>
      </div>
      <el-button type="primary" @click.native="submit">提交</el-button>
    </el-dialog>
    <!-- 添加 -->
    <el-dialog
      title="新增商品库商品"
      :close-on-click-modal="false"
      :visible.sync="list"
      width="40%"
      @close="close"
      center
    >
      <el-form
        :model="ruleVlue"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="between">
          <el-form-item label="所属分类" prop="category_id">
            <el-cascader
              :options="ruleForm.CategoryList"
              :props="{ value: 'id', label: 'name', checkStrictly: true }"
              clearable
              v-model="categoryid"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="所属省份" prop="province_id">
            <el-select
              v-model="ruleVlue.province_id"
              placeholder="请选择省份"
              clearable
            >
              <el-option
                v-for="item in ruleForm.provinceCity"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="province_City"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称" prop="veg_name">
            <el-input
              v-model="ruleVlue.veg_name"
              style="width:217px"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属城市" prop="city_id">
            <el-select
              v-model="ruleVlue.city_id"
              clearable
              placeholder="请选择城市"
            >
              <el-option
                v-for="item in ruleForm.getcity.children"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="getcityChildren"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="between">
          <el-form-item label="商品编码" prop="veg_code">
            <el-input
              style="width:217px"
              placeholder="请输入商品编码"
              v-model.number="ruleVlue.veg_code"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属区县">
            <el-select
              v-model="ruleVlue.area_id"
              clearable
              placeholder="请选择区域"
            >
              <el-option
                v-for="item in ruleForm.areaid.children"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="areaidChildren"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品别名" prop="veg_alias_name">
            <el-input
              v-model="ruleVlue.veg_alias_name"
              style="width:217px"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属街道" prop="street_id">
            <el-select
              v-model="ruleVlue.street_id"
              clearable
              placeholder="请选择街道"
            >
              <el-option
                v-for="item in ruleForm.streetid.children"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品图片" prop="veg_logo">
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :http-request="uploadSectionFile"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="ruleVlue.veg_logo"
                :src="ruleVlue.veg_logo"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="计量单位" prop="unit_id">
            <el-select
              v-model="ruleVlue.unit_id"
              placeholder="请选择计量单位"
              clearable
            >
              <el-option
                v-for="item in ruleForm.units"
                :key="item.id"
                :label="item.units_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import {
  GETproduct,
  getCategoryList,
  POSTproduct,
  PUTproduct,
  GETunits
} from '../../../api/productList'
import { provinceCityArea, getcity } from '../../../api/layout'
import { upload } from '../../../utile/upload'
export default {
  name: 'commodityList',
  data () {
    return {
      add: false, //  编辑标识
      list: false, // 添加标识
      tableData: [], // 表格数据
      ID: '', // 编辑时的id
      value: '',
      options: '',
      page: '', // 页码
      limit: '', // 一页的数据
      categoryid: '', // 所属类目
      ruleForm: {
        CategoryList: '', // 所属分类数据
        provinceCity: '', // 所属省份数据
        getcity: '', // 城市数据
        areaid: '', // 区域数据
        streetid: '', // 街道数据
        units: '' // 计量单位数据
      },
      // 添加收集到的数据
      ruleVlue: {
        category_id: '', // 所属分类ID
        veg_name: '', // 商品名称
        veg_alias_name: '', // 商品别名
        province_id: '', // 省份ID
        city_id: '', // 城市ID
        area_id: '', // 区域ID
        street_id: '', // 街道ID
        status: '1',
        refuse_reson: '', // 审核拒绝原因
        veg_logo: '', // 商品图片
        veg_code: '', // 商品编码
        unit_id: '' // 计量单位
      },
      rules: {
        category_id: [
          { required: true, message: '请选择所属分类', trigger: 'blur' }
        ],
        veg_name: [
          { required: true, message: '请填写商品名称', trigger: 'chang' }
        ],
        province_id: [
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        city_id: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        veg_logo: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请上传图片'))
              } else {
                callback()
              }
            },
            trigger: 'change',
            required: true
          }
        ],
        veg_code: [
          { required: true, message: '请填写商品编码', trigger: 'blur' }
        ],
        unit_id: [
          { required: true, message: '请选择计量单位', trigger: 'blur' }
        ]
      },
      textarea1: ''
    }
  },
  watch: {
    categoryid (val) {
      this.ruleVlue.category_id = val[val.length - 1]
    }
  },
  methods: {
    // 表格数据
    GET_product ({ page = '', limit = 10 } = {}) {
      GETproduct({ page, limit }).then(res => {
        this.tableData = res.data
      })
    },
    // 编辑按钮
    handleEdit (row) {
      console.log(row)
      this.categoryid =
        row.parent_category_id * 1 !== 0
          ? [row.parent_category_id * 1, row.category_id * 1]
          : [row.category_id * 1]
      this.ruleVlue.veg_name = row.veg_name
      this.ruleVlue.veg_alias_name = row.veg_alias_name
        ? row.veg_alias_name
        : ''
      this.ruleVlue.province_id = row.province_id
      this.ruleVlue.city_id = row.city_id
      this.ruleVlue.area_id = row.area_id
      this.ruleVlue.veg_logo = row.veg_logo
      this.ruleVlue.veg_code = row.veg_code
      this.ruleVlue.unit_id = row.unit_id
      this.ruleVlue.street_id = row.street_id
      this.ruleVlue.refuse_reson = row.refuse_reson ? row.refuse_reson : ''
      this.ruleVlue.status = `${row.status}`
      this.ID = row.id
      this.add = true
    },
    close () {
      this.ruleVlue.category_id = ''
      this.ruleVlue.veg_name = ''
      this.ruleVlue.veg_alias_name = ''
      this.ruleVlue.province_id = ''
      this.ruleVlue.city_id = ''
      this.ruleVlue.area_id = ''
      this.ruleVlue.veg_logo = ''
      this.ruleVlue.veg_code = ''
      this.ruleVlue.unit_id = ''
      this.ruleVlue.street_id = ''
      this.ruleVlue.refuse_reson = ''
      this.ruleVlue.status = '1'
      this.ID = ''
      this.categoryid = ''
    },
    // 编辑提交
    submit () {
      if (this.ruleVlue.status * 1 === 2 && !this.ruleVlue.refuse_reson) {
        this.$message.error('请输入不通过的原因哦！')
        return
      }
      PUTproduct({ data: this.ruleVlue, id: this.ID }).then(res => {
        console.log(res.code)
        if (res.code === 200) {
          this.add = false
          this.close()
          this.GET_product({ page: this.page, limit: this.limit })
          this.$message.success(`${res.data}`)
        } else {
          console.log(res.code)
          this.$message.error(`${res.msg}`)
        }
      })
    },
    // 所属分类数据
    get_CategoryList () {
      getCategoryList().then(res => {
        if (res.code === 200) {
          this.ruleForm.CategoryList = res.data
        }
      })
    },
    // 所属省份
    get_provinceCityArea () {
      provinceCityArea().then(res => {
        this.ruleForm.provinceCity = res.data
      })
    },
    // 选择啦省份
    province_City () {
      getcity(this.ruleVlue.province_id).then(res => {
        this.ruleForm.getcity = res.data
      })
      this.ruleVlue.city_id = ''
      this.ruleVlue.area_id = ''
      this.ruleVlue.street_id = ''
    },
    // 选择啦城市
    getcityChildren () {
      getcity(this.ruleVlue.city_id).then(res => {
        this.ruleForm.areaid = res.data
      })
      this.ruleVlue.area_id = ''
      this.ruleVlue.street_id = ''
    },
    //  选择啦区域
    areaidChildren () {
      getcity(this.ruleVlue.area_id).then(res => {
        this.ruleForm.streetid = res.data
      })
      this.ruleVlue.street_id = ''
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径
    uploadSectionFile (param) {
      // this.ruleVlue.veg_logo = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleVlue.veg_logo = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 添加商品库商品
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleVlue,
            area_id: this.ruleVlue.area_id ? this.ruleVlue.area_id : 0,
            street_id: this.ruleVlue.street_id ? this.ruleVlue.street_id : 0
          }
          delete data.status
          delete data.refuse_reson
          POSTproduct(data).then(res => {
            if (res.code === 200) {
              this.$message.success(`${res.data}`)
              this.list = false
              this.close()
              this.GET_product({ page: this.page, limit: this.limit })
            }
          })
        } else {
          return false
        }
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.GET_product({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.GET_product({ page: val })
    },
    // 计量单位
    GET_units () {
      GETunits({ page: '', limit: 10000 }).then(res => {
        this.ruleForm.units = res.data.data
      })
    }
  },

  mounted () {
    this.GET_product()
    this.get_CategoryList()
    this.get_provinceCityArea()
    this.GET_units()
  }
}
</script>

<style lang="less" scoped>
.footers {
  text-align: right;
  margin-top: 10px;
}
.list {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
/deep/ .btn {
  width: 120px;
}

.amend {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.margin20 {
  margin: 20px 0;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.between {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.demo-ruleForm {
  padding: 0 20px;
}
/deep/ .el-textarea__inner {
  height: 80px !important;
}
</style>
